<template>
    <div>
        <Header></Header>
        <section class="intro-au" id="intro">
            Texttexttext
        </section>
        <section class="section section-au">
            <div class="container">

                <div class="onas">
                    <h3 class="block__title block__title-onas">О нас</h3>
                    <div class="onas__text">
                        <p class="onas__paragraph">
                            Текст о компании (он же “о фирме”, он же “о нас”, он же “о предприятии” и т.д.) чем-то по своей сути напоминает японскую ядовитую рыбу фугу. Стоит его чуть-чуть “передержать”, и вместо вызова доверия и расположения к себе читателя, материал начинает отталкивать и вызывать отвращение. А виной тому тонкости человеческой психологии. Только их мало кто учитывает. Кстати, говорят, что раньше повар, который неправильно приготовил рыбу фугу, должен был сам ее съесть. Интересно, как бы это смотрелось в сфере копирайтинга… Написал текст с обратным эффектом — изволь разместить его у себя на сайте. На самом видном месте. Пущай все смотрят!
                        </p>
                        <p class="onas__paragraph">
                            Это я, конечно, утрирую. И смех смехом, но на большинстве сайтов тексты о компании можно свести к трем предложениям: “Мы очень классные, давно на рынке, клиентоориентированные и надежные. Лучше нас никого нет. Заказывайте у нас!”. Другими словами, эти тексты представляют собой, знаете, эдакую эпическую оду, каждое слово которой — сладкий мед. Даже слишком сладкий. Приторный. Особенно этим грешит средний бизнес, руководство которого аж млеет от фраз вроде “…делая акцент на клиентоориентированности, инновационных технологиях и индивидуальных решениях для бизнеса клиента…”
                        </p>
                    </div>
                </div>
                <div class="ask">
                    <div class="ask__contacts">
                        <div class="contact__line">
                            <img :src="`${staticDomain}/img/about-us/marker-icon.png`" class="au__marker-icon" alt="">г. Киев, ул Киевская 325/2
                        </div>
                        <div class="contact__line">
                            <img :src="`${staticDomain}/img/about-us/iphone-icon.png`" class="au__iphone-icon" alt=""> <a href="tel:+80976559812">8 (097) 655-98-12</a>
                        </div>
                        <div class="contact__line">
                            <img :src="`${staticDomain}/img/about-us/mail-icon.png`" alt=""> <a href="mailto:info_distribune@mail">info_distribune@mail</a>
                        </div>

                    </div>
                    <div class="ask__form">
                        <ContactForm></ContactForm>
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </div>
</template>

<script>
    import ContactForm from "../components/ContactForm";
    import Header from "../components/TheHeader";
    import Footer from "../components/TheFooter";
    export default {
        name: "AboutUsScreen",
        components: {
            ContactForm,
            Header,
            Footer,
        },
        data(){
            return{
                staticDomain: this.$store.state.static.staticDomain,
            }
        },
    }
</script>

<style lang="scss" scoped>
    ::v-deep .contact {
        width: auto;
        margin: auto;

        .form__input {
            width: 354px;
        }
        .form__textarea {
            width: 354px;
        }
    }

</style>
